/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from "react";
import { useAuth } from "../hooks";

const WithAuth: FC = ({ children }) => {
  const auth = useAuth();

  useEffect(() => {
    if (!auth.isAuthenticated) {
      auth.login();
    }
  }, []);

  return <>{auth.isAuthenticated && children}</>;
}

export default WithAuth;

import axios from "../../common/api/custom-axios";
import config from "../../configs";
import { Role } from "../roles";

export class UserService {
    
    private userRelUri = "/user";
    private permissionsRelUri = "/permissions";

    getPermissions(): Promise<Role[]> {
        return axios.get(config.endpoint + this.userRelUri + this.permissionsRelUri).then(rsp => rsp.data);
    }
}

const userService = new UserService();

export default userService;
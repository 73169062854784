import { Component } from "react";
import { Pagination, PaginationItem, PaginationLink, Container } from "reactstrap";
import "./Paginator.css";

const pagesShown = 9;

interface PaginatorProps {
    currentPage?: number;
    pageCount: number;
    changePage: (page: number) => void
}

interface PaginatorState {
    currentPage: number;
    start: number;
    end: number;
}

export class Paginator extends Component<PaginatorProps, PaginatorState> {

    constructor(props: PaginatorProps) {
        super(props);
        this.state = {
            currentPage: this.props.currentPage ?? 0,
            start: 0,
            end: pagesShown - 1,
        }
    }

    componentDidMount() {
        this.setupLimits();
    }

    componentDidUpdate() {
        this.setupLimits();
    }

    render() {
        return (
            <Container>
                {this.props.pageCount > 0 &&
                    <Pagination className="paginator text-center">
                        <PaginationItem disabled={this.state.currentPage === 0}>
                            <PaginationLink first onClick={() => this.handleClick(0)}></PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={this.state.currentPage === 0}>
                            <PaginationLink previous onClick={() => this.handleClick(this.state.currentPage - 1)}></PaginationLink>
                        </PaginationItem>

                        {this.state.start > 0 &&
                            <PaginationItem>
                                <PaginationLink onClick={() => this.handleClick(0)}>1</PaginationLink>
                            </PaginationItem>
                        }

                        {this.state.start >= 2 &&
                            <PaginationItem disabled>
                                <PaginationLink>...</PaginationLink>
                            </PaginationItem>
                        }

                        {this.range(this.state.start, this.state.end).map((i) =>
                            <PaginationItem active={i === this.state.currentPage} key={i}>
                                <PaginationLink onClick={() => this.handleClick(i)}>{i + 1}</PaginationLink>
                            </PaginationItem>
                        )}

                        {this.state.end <= this.props.pageCount - 3 &&
                            <PaginationItem disabled>
                                <PaginationLink>...</PaginationLink>
                            </PaginationItem>
                        }

                        {this.state.end < this.props.pageCount - 1 &&
                            <PaginationItem>
                                <PaginationLink onClick={() => this.handleClick(this.props.pageCount - 1)}>{this.props.pageCount}</PaginationLink>
                            </PaginationItem>
                        }

                        <PaginationItem disabled={this.state.currentPage === this.props.pageCount - 1}>
                            <PaginationLink next onClick={() => this.handleClick(this.state.currentPage + 1)}></PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={this.state.currentPage === this.props.pageCount - 1}>
                            <PaginationLink last onClick={() => this.handleClick(this.props.pageCount - 1)}></PaginationLink>
                        </PaginationItem>
                    </Pagination>
                }
            </Container>
        );
    }

    setupLimits() {
        const pagesToEachSide = Math.floor(pagesShown/2);

        var start = 0;
        var end = pagesShown - 1;
        
        if (this.props.pageCount < pagesShown) {
            end = this.props.pageCount - 1;
        } else {
            if (this.state.currentPage - pagesToEachSide >= 0) {
                start = this.state.currentPage - pagesToEachSide;
                end = start + pagesShown - 1;   
            }

            if (this.state.currentPage + pagesToEachSide >= this.props.pageCount) {
                end = this.props.pageCount - 1;
                start = end - pagesShown + 1;
            }
        }
        
        if (this.state.start !== start || this.state.end !== end) {
            this.setState({start: start, end: end});
        }
        
    }

    handleClick(page: number) {
        this.setState({currentPage: page});
        this.props.changePage(page);
    }

    range(startAt: number, endAt: number) {
        return Array.from(Array(endAt - startAt + 1).keys()).map(i => i + startAt);
    }
}
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Spinner, Table } from "reactstrap";
import { Paginator } from "../common/components";
import { PagedResult } from "../common/models/paged-result";
import ToastService from "../common/services/toast-service";
import TransactionsService, { TransactionDetail as TransactionDetailDTO } from "./transactions-service";

const TransactionDetail: FC = () => {
  const [data, setData] = useState<PagedResult<TransactionDetailDTO>>();
  const history = useHistory();

  const getSearchParams = () => {
    const query = new URLSearchParams(history.location.search);

    return {
        apiKey: query.get("apiKey") || "",
        sdkVersion: query.get("sdkVersion") || "",
        to: query.get("toDate") ? new Date(query.get("toDate")!) : undefined,
        from: query.get("fromDate") ? new Date(query.get("fromDate")!) : undefined
    };
} ;

  const getDetail = async (page: number) => {
    try {
      setData(undefined);

      const detail = await TransactionsService.getDetail(
        history.location.pathname.substring(history.location.pathname.lastIndexOf("/") + 1),
        getSearchParams(),
        page);

      setData(detail);
    } catch (error: any) {
      ToastService.showToast("Error applying search filters", error);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const page = parseInt(params.get("page") || "0");
    getDetail(page);
  }, []);

  useEffect(() => {
    if (history.location.search) {
      const params = new URLSearchParams(history.location.search);
      const page = parseInt(params.get("page") || "0");
      getDetail(page);
    }
  }, [history.location]);

  const buildTable = () => (
    <Table bordered responsive striped size="lg">
      <thead>
        <tr>
          <th key="timestamp-header">Timestamp</th>
          <th key="device-header">Device</th>
          <th key="brand-header">Brand</th>
          <th key="model-header">Model</th>
          <th key="os-header">Device OS</th>
          <th key="sdk-version-header">SDK Version</th>
        </tr>
      </thead>
      <tbody>
        { 
          !data || data.resources.length === 0 ?
            <tr>
              <td colSpan={2}>No Data Available</td>
            </tr> :
          data.resources.map((row, i) => (
            <tr>
              <td key="timestamp">{row.createdAt}</td>
              <td key="device">{row.deviceId}</td>
              <td key="brand">{row.deviceBrand}</td>
              <td key="model">{row.deviceModel}</td>
              <td key="os">{row.deviceOS}</td>
              <td key="sdk-version">{row.sdkVersion}</td>
            </tr>
          ))
        }
      </tbody>      
    </Table>
  );

  const changePage = (page: number) => {
    const params = new URLSearchParams(history.location.search);
    params.set("page", page.toString());
    console.log(params.toString());
    console.log(history.location.pathname);
    history.push(`${history.location.pathname}?${params.toString()}`);
  }

  return (
    <Container>
      {data?.resources ?
        <Container>
            {buildTable()}
            <Paginator 
              pageCount={data.pageCount}
              changePage={changePage}
              currentPage={data.currentPage}
            />
        </Container> :
        <Spinner color="primary" />
      }  
      </Container>
  );
};

export default TransactionDetail;

import { Component } from "react";
import { Button, Container, Spinner, Table } from "reactstrap";
import { ApiKeyNewModal } from "../new/ApiKeyNewModal";
import { ApiKeyEditModal } from "../edit/ApiKeyEditModal";
import ApiKeyService from "../common/services/api-key.service";
import { Paginator } from "../../common/components/paginator/Paginator";
import "./ApiKeyList.css";
import ToastService from "../../common/services/toast-service";
import { PagedResult } from "../../common/models/paged-result";
import { APIKeyDTO } from "../common/models/api-key-dto";
import { RouteComponentProps } from "react-router-dom";
import { NewApiKeyRequest } from "../common/models/new-api-key-request";
import { EditApiKeyRequest } from "../common/models/edit-api-key-request";
import config from '../../configs';
import Moment from 'moment';
import { APIKeyType } from '../common/models/api-key-type';

interface ColumnDef {
    header: string,
    accessor: string,
}

const columns: ColumnDef[] = [
    {
        header: 'Name',
        accessor: 'name'
    },
    {
        header: 'Token',
        accessor: 'token'
    },
    {
        header: 'Description',
        accessor: 'description'
    },
    {
        header: 'Valid Since',
        accessor: 'validSince'
    },
    {
        header: 'Valid Until',
        accessor: 'validUntil'
    }
];

interface ApiKeyListState {
    data?: PagedResult<APIKeyDTO>;
    permissionsColumns?: string[];

    isLoading: boolean;
    generateModalOpen: boolean;
    editModalOpen: boolean;
    activeApiKey?: APIKeyDTO;
}

export class ApiKeyList extends Component<RouteComponentProps, ApiKeyListState> {

    apiKeyType = APIKeyType.Mobile;

    constructor(props: RouteComponentProps) {
        super(props);

        if (props.match.url.indexOf('consumer-keys') >= 0)
            this.apiKeyType = APIKeyType.Consumer; 

        this.state = {
            isLoading: false,
            generateModalOpen: false,
            editModalOpen: false,
        }
    }

    componentDidMount() {
        this.getPagedResources();
    }

    componentDidUpdate(prevProps: RouteComponentProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.getPagedResources();
        }
    }

    render() {
        return (
            <Container>
                <Button className="generate-button" color="primary" onClick={() => this.toggleGenerateModal()}>Generate Api Key</Button>
                <Spinner color="primary" className={this.state.data && !this.state.isLoading ? "invisible" : "visible"}></Spinner>
                {this.state.data && !this.state.isLoading &&
                    <Container>
                        <Table bordered responsive size="lg">
                            <thead>
                                <tr>
                                    {columns.map((column, idx) => (<th key={column.accessor} className={idx === 0 ? "sticky-begin" : ""}>{column.header}</th>))}
                                    {this.state.permissionsColumns?.map(permission => (<th key={permission}>{permission}</th>))}
                                    <th className="sticky-end">Actions</th>
                                </tr>
                            </thead>
                            {this.state.data.resources.length === 0 &&
                                <tbody>
                                    <tr>
                                        <td colSpan={(columns.length + 1)}>No Data Available</td>
                                    </tr>
                                </tbody>
                            }
                            {this.state.data.resources.length > 0 &&
                                <tbody>
                                    {this.state.data.resources.map((row, i) => (
                                        <tr key={i}>
                                            {columns.map((column, idx) => (
                                                <td key={i + '-' + column.accessor} className={idx === 0 ? "sticky-begin" : ""}>
                                                    {this.isDate(column) && this.formatDate((row as {[key: string]: any})[column.accessor])}
                                                    {!this.isDate(column) && (row as {[key: string]: any})[column.accessor]}
                                                </td>
                                            ))}

                                            {this.state.permissionsColumns?.map(permission => (
                                                <td key={permission} className="permission-column">
                                                    {row.permissions[permission] && <i className="fa fa-check"></i>}
                                                </td>
                                            ))}

                                            <td key={i + '- action'} className="sticky-end"><Button color="default" onClick={() => this.toggleEditModal(row)}><i className="fa fa-pencil"></i></Button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            }
                        </Table>
                        <Paginator pageCount={this.state.data.pageCount} changePage={(page: number) => this.handlePageChange(page)} currentPage={this.state.data.currentPage}></Paginator>
                    </Container>
                }

                <ApiKeyNewModal 
                    isOpen={this.state.generateModalOpen} 
                    type={this.apiKeyType}
                    toggle={() => this.toggleGenerateModal()} 
                    submit={(data: NewApiKeyRequest) => this.createApiKey(data)}>
                </ApiKeyNewModal>
                <ApiKeyEditModal 
                    isOpen={this.state.editModalOpen} 
                    toggle={() => this.toggleEditModal()}
                    type={this.apiKeyType}
                    submit={(token: string, data: EditApiKeyRequest) => this.editApiKey(token, data)}
                    delete={(token: string) => this.deleteApiKey(token)}
                    apiKey={this.state.activeApiKey!}>
                </ApiKeyEditModal>
            </Container>

        );
    }

    handlePageChange(page: number) {
        this.props.history.push(this.props.location.pathname + "?page=" + page);
    }

    toggleGenerateModal() {
        this.setState({generateModalOpen: !this.state.generateModalOpen});
    }

    createApiKey(apiKey: NewApiKeyRequest) {
        this.setState({
            isLoading: true,
        })

        ApiKeyService.createApiKey(apiKey)
            .then(() => {
                this.setState({
                    isLoading: false,
                })

                this.getPagedResources();
                ToastService.showToast("Success", "API Key generated", true);
            })
            .catch((error) => {
                ToastService.showToast("Error generating API Key", error.response.data.detail ?? error.response.data.title)
            });
    }

    toggleEditModal(apiKey: APIKeyDTO|undefined = undefined) {
        this.setState({editModalOpen: !this.state.editModalOpen, activeApiKey: apiKey})
    }

    editApiKey(token: string, apiKey: EditApiKeyRequest) {
        this.setState({
            isLoading: true,
        })

        ApiKeyService.updateApiKey(token, apiKey)
            .then(() => {
                this.setState({
                    isLoading: false,
                })

                this.getPagedResources();
                ToastService.showToast("Success", "API Key updated", true);
            })
            .catch((error) => {
                ToastService.showToast("Error update API Key", error.response.data.detail);
            });
    }

    deleteApiKey(token: string) {
        this.setState({
            isLoading: true,
        })

        ApiKeyService.deleteApiKey(token)
            .then(() => {
                this.setState({
                    isLoading: false,
                })

                this.getPagedResources();
                ToastService.showToast("Success", "API Key deleted", true);
            })
            .catch((error) => {
                ToastService.showToast("Error deleting API Key", error.response.data.detail);
            });
    }

    getPagedResources() {
        this.setState({
            isLoading: true,
        })

        ApiKeyService.getPaged(this.props.location.search, this.apiKeyType)
            .then((page) => {
                if (page.resources.length === 0) {
                    this.setState({
                        data: page,
                        isLoading: false,
                        permissionsColumns: Object.keys([])
                    });
                } else {
                    this.setState({
                        data: page,
                        isLoading: false,
                        permissionsColumns: Object.keys(this.apiKeyType === APIKeyType.Mobile ? page.resources[0].permissions : [])
                    });
                }
            });
    }

    isDate(column: ColumnDef): boolean {
        return column.accessor === "validUntil" || column.accessor === "validSince";
    }

    formatDate(date: any): string {
        var dateObj = new Date(date)
        return Moment(dateObj).format(config.dateFormats.apiKeyListView)
    }
}

import axios from '../../../common/api/custom-axios';
import config from '../../../configs';
import { TermsAndConditionsFiltersData } from "../models/terms-conditions-filters-data";
import { UploadTermsAndConditionsRequest } from '../models/upload-terms-conditions-request';

class TermsAndConditionsService {
    
    private termsConditionsRelUri = "terms-conditions";

    getFiltersData(): Promise<TermsAndConditionsFiltersData> {
        return axios.get([config.endpoint, this.termsConditionsRelUri].join("/")).then(rsp => rsp.data);
    }

    updateTermsAndConditionsFile(request: UploadTermsAndConditionsRequest): Promise<void> {
        var formData = new FormData()
        formData.append("file", request.file)
        return axios
            .post(
                [config.endpoint, this.termsConditionsRelUri, request.locale].join("/"),
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }
            )
            .then(rsp => rsp.data);
    }
}

const termsAndConditionsService = new TermsAndConditionsService()

export default termsAndConditionsService
import { PagedResult } from "../../../common/models/paged-result";
import { TransactionSummary } from "../../common/models/transaction-summary-dto";
import { Table } from "reactstrap";
import { Link } from 'react-router-dom';
import { ReportsSearchRequest } from "../../common/models/reports-search-request";
import { NavLink } from 'reactstrap';

const TransactionList = (props: { result: PagedResult<TransactionSummary>, searchData: ReportsSearchRequest }) => {
    const { result, searchData } = props;

    const buildSearchQuery = (featureType: string, sdkVersion: string) => {
        const params = {
            from: searchData.from,
            to: searchData.to,
            apiKey: searchData.apiKey,
            sdkVersion: sdkVersion,
        } as {[key: string]: any};

        var query = Object.keys(params).filter(key => !!(params[key])).map(key => key + '=' + params[key]).join('&');
        return `/feature/${featureType}?${query}`;
    };

    return <Table bordered responsive striped size="lg">
            <thead>
                <tr>
                    <th key="type-header">Type</th>
                    <th key="total-header">Total</th>
                    <th key="sdk-version-header">SDK Version</th>
                    <th key="success-rate-header">Success Rate</th>
                </tr>
            </thead>
            {result.resources.length === 0 &&
                <tbody>
                    <tr>
                        <td colSpan={4}>No Data Available</td>
                    </tr>
                </tbody>
            }
            {result.resources.length > 0 &&
                <tbody>
                {result.resources.map((row, i) => (
                        <tr key={i}>
                            <td key="feature">
                                <NavLink tag={Link} to={() => buildSearchQuery(row.feature, row.sdkVersion)}>{row.feature}</NavLink>
                            </td>
                            <td key="total-count">
                                {row.total}
                            </td>
                            <td key="sdk-version">
                                {row.sdkVersion}
                            </td>
                            <td key="success-rate">
                                {row.successRate * 100}%
                            </td> 
                        </tr>
                    ))}
                </tbody>
            }
        </Table>
}

export default TransactionList;


import { useEffect, useState } from "react"
import { RegulaLicense } from "../common/models/files-data";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row, Table } from "reactstrap"
import { FileInput } from "../../common/components/file-input/FileInput";

interface LicensesProps {
    apiKey: string
    currentLicense?: RegulaLicense
    onSubmit: (license: File, expiryDate: string) => void;
    onDelete: (license: RegulaLicense) => void;
}

export const Licenses = (props: LicensesProps) => {
    const [license, setLicense] = useState<File | undefined>(undefined);
    const [expiryDate, setExpiryDate] = useState<string>("");
    const [isInvalidExpiryDateField, setIsInvalidExpiryDateField] = useState<boolean>(false);

    const isExpiryDateValid = (): boolean => {
        return expiryDate !== '';
    }

    const isFormValid = (): boolean => {
        return isExpiryDateValid() && license !== undefined;
    }

    const resetForms = () => {
        setLicense(undefined);
        setExpiryDate("");
        setIsInvalidExpiryDateField(false);
    }

    const onUpload = () => {
        if (license !== undefined && isExpiryDateValid()) {
            props.onSubmit(license, expiryDate)
            resetForms()
        } else {
            setIsInvalidExpiryDateField(true)
        }
    }

    const onDateChanged = (date: string) => {
        setExpiryDate(date)
        setIsInvalidExpiryDateField(false)
    }

    const onDelete = (license: RegulaLicense | undefined) => {
        if (license !== undefined) {
            props.onDelete(license)
        }
    }

    useEffect(() => {
        resetForms()
    },[props.apiKey]);

    return (
        <Container>
            <Form>
                <Row form>
                    <Col sm={3}>
                        <FormGroup>
                            <FileInput label="Upload new Regula license:" accept=".license" onFileSelected={setLicense}/>
                        </FormGroup>
                    </Col>
                    <Col sm={3}>
                        <FormGroup>
                            <Label for="expiryDate">Expiry Date</Label>
                            <Input
                                type="date"
                                name="expiryDate"
                                invalid={isInvalidExpiryDateField}
                                value={expiryDate}
                                onChange={e => onDateChanged(e.target.value)}>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col sm={3} className="align-self-end">
                        <FormGroup>
                            <Button color="primary" disabled={!isFormValid()} onClick={() => onUpload()}>Upload</Button>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
            <Form>
                <Row form>
                    <Col>
                    <Table bordered responsive size="lg">
                        <thead>
                            <tr>
                                <th key={'file'} className={"sticky-begin"}>{"File"}</th>
                                <th key={'date'} className={"sticky-begin"}>{"Expiry Date"}</th>
                                <th key={'actions'} className={"table-last-col"}>{"Actions"}</th>
                            </tr>
                        </thead>
                        {!props.currentLicense &&
                            <tbody>
                                <tr>
                                    <td colSpan={3}>No Data Available</td>
                                </tr>
                            </tbody>
                        }
                        {props.currentLicense &&
                            <tbody>
                            <tr key={props.currentLicense.path}>
                                <td key={props.currentLicense.path + '-path'} className={"sticky-begin"}>
                                    <div className={"table-col"}>{<a href={props.currentLicense.path} rel="noreferrer">{props.currentLicense.path}</a>}</div>
                                    </td>
                                <td key={props.currentLicense.expiryDate + '-date'} className={"sticky-begin"}>
                                        <div className={"table-col"}>
                                        {props.currentLicense.expiryDate &&
                                            <Label for={props.currentLicense.expiryDate}>{props.currentLicense.expiryDate}</Label>
                                        }
                                        {!props.currentLicense.expiryDate &&
                                            <Label className="text-align left" for={props.currentLicense.expiryDate}>No data</Label>
                                        }
                                    </div>
                                </td>
                                <td key={props.currentLicense.path + '- action'} className="sticky-end table-last-col">
                                    <div className={"table-last-col-content"}><Button color="default" onClick={() => onDelete(props.currentLicense)}><i className="fa fa-trash"></i></Button></div>
                                </td>
                            </tr>
                            </tbody>
                        }
                    </Table>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
}
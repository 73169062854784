import { FC } from "react";
import { Input } from "reactstrap";

interface ISelectorList {
    text: string;
    value: string;
}

export interface ISelector {
    id: string;
    values: ISelectorList[];
    onChange: (value: string) => any;
    value: string;
}

export const Selector: FC<ISelector> = (props) => {
    const mapValues = () => props.values.map(v => {
        return <option key={v.value} value={v.value}>{v.text}</option>;
    });

    return (
        <Input 
            type="select" 
            name="select" 
            id={props.id}
            onChange={e => props.onChange(e.target.value)}
            value={props.value}
        >
            {mapValues()}
        </Input>
    )
};

import { FC } from "react";
import { Form, Row, Col } from "reactstrap"
import { SelectorFormGroup } from "../common/components"
import { ClientData } from "./SdkSettingsService";

interface formParams {
    apiKeysList: ClientData[];
    deviceOsList: string[];
    currentApiKey: string;
    currentDeviceOS: string;
    onApiKeyChange: (apiKey: string) => void;
    onDeviceOSChange: (deviceOS: string) => void;
}

const SdkSettingsForm: FC<formParams> = (form: formParams) => {
    const transformApiKeyList = () => {
        const list = form.apiKeysList?.map(c => ({
            text: c.name,
            value: c.token
        })) || [];

        if (list) {
            list.unshift({ text: "All", value: ""});
            return list;
        }
        
        return [];
    }

    const transformDeviceOsList = () => {
        const list = form.deviceOsList?.map(d => ({
            text: d,
            value: d
        })) || [];

        const index = list.findIndex(l => !!!l.text);
        
        if (index !== -1) {
            list[index].text = "None";
        }

        if (list) {
            list.unshift({ text: "All", value: "All"});
            return list;
        }
        
        return [];
    }

    return (
        <Form>
            <Row form>
                <Col sm={3}>
                    <SelectorFormGroup 
                        title="Client" 
                        id="apiKeySelect" 
                        values={transformApiKeyList()} 
                        onChange={(value) => form.onApiKeyChange(value)} 
                        value={form.currentApiKey} 
                    />
                </Col>
                <Col sm={2}>
                    <SelectorFormGroup 
                        title="DeviceOS" 
                        id="deviceOsSelect" 
                        values={transformDeviceOsList()} 
                        onChange={(value) => form.onDeviceOSChange(value)} 
                        value={form.currentDeviceOS}
                    />
                </Col>                    
            </Row>
        </Form>
    )
};

export default SdkSettingsForm;

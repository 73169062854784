import { useState } from "react";
import * as locale from 'locale-codes'
import Select from 'react-select';
import { LanguageOption } from "../../common/models/languageOption";
import { Button, Col, FormFeedback, Container, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import TermsAndConditionsService from "../common/services/terms-conditions-service";
import { UploadTermsAndConditionsRequest } from "../common/models/upload-terms-conditions-request";
import ToastService from "../../common/services/toast-service";
import { FileInput } from "../../common/components/file-input/FileInput";
import "./TermsAndConditionsAddModal.css";

interface TermsAndConditionsAddProps {
    isOpen: boolean;
    toggle: () => void;
    submit: (language: string, file: File) => void;
};

export const TermsAndConditionsAddModal = (props:  TermsAndConditionsAddProps) => {

    const [file, setFile] = useState<File|undefined>(undefined);
    const [language, setLanguage] = useState<string|undefined>(undefined);

    const isConfirmButtonEnabled = (): boolean => {
        return language !== undefined && language !== "" && file !== undefined
    }

    const submit = () => {
        if (language !== undefined && file !== undefined) {
            var request: UploadTermsAndConditionsRequest = {
                file: file,
                locale: language
            } 
            TermsAndConditionsService.updateTermsAndConditionsFile(request)
            .then((data) => {
                props.submit(language, file)
                props.toggle()
            })
            .catch(error => {
                ToastService.showToast("Error adding terms and conditions file", error.response.data.detail ?? error.response.data.title)
            })
        }
    }

    const languageOptions = (): LanguageOption[] => {
        return locale.all.map((locale, i) => {
            if (locale.location !== null) {
                return { value: locale.tag, label: locale.name + " - " + locale.location + " (" + locale.tag + ")" }
            } else {
                return { value: locale.tag, label: locale.name + " (" + locale.tag + ")" }
            }
        })
    }

    return (
        <Container>
        {props.isOpen &&
            <Modal isOpen={props.isOpen} toggle={() => props.toggle()} size="md" unmountOnClose={true}>
                <ModalHeader toggle={() => props.toggle()}>Add Language</ModalHeader>
                <ModalBody>
                    <Form>
                        <Container>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="language">Language</Label>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isClearable={true}
                                            isSearchable={true}
                                            name="language"
                                            onChange={e => setLanguage(e?.value)}
                                            options={languageOptions()}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormFeedback>This field is required</FormFeedback>
                                        <FileInput label="Terms And Conditions HTML File" accept=".html" onFileSelected={setFile}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        disabled={!isConfirmButtonEnabled()}
                        onClick={() => submit()}>Confirm</Button>
                    <Button color="secondary" onClick={() => props.toggle()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        }
        </Container>
    );

}
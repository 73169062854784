import { FC, useState } from "react";
import { Button, Table, Container } from "reactstrap";
import SdkSettingsEditModal from "./SdkSettingEditModal";
import { SdkSetting } from "./SdkSettingsService";

const columns = [
    "apiKey",
    "feature",
    "key",
    "value",
    "deviceOS",
    "description"
];

interface TableData {
    settings: SdkSetting[]
    onSettingUpdate: (setting: SdkSetting) => any
}

const createHeaders = (headers: string[]) => (
    <thead>
        <tr>
            {headers.map(c => (<th key={`${c}-key`}>{c}</th>))}
            <th className="sticky-end">actions</th>
        </tr>
    </thead>
);

const mapSettings = (settings: SdkSetting[], onActionClick: (setting: SdkSetting) => void) => settings.map((row, i) => (
    <tr key={i}>
        <td key={`${i}-apiKey`}>{row.apiKey}</td>
        <td key={`${i}-feature`}>{row.feature}</td>
        <td key={`${i}-key`}>{row.key}</td>
        <td key={`${i}-value`}>{row.value}</td>
        <td key={`${i}-deviceOS`}>{row.deviceOS}</td>
        <td key={`${i}-description`}>{row.description}</td>
        <td key={`${i}-action`} className="sticky-end">
            <Button color="default" onClick={() => onActionClick(row)}>
                <i className="fa fa-pencil"></i>
            </Button>
        </td>
    </tr>
));

const SdkSettingsTable: FC<TableData> = (data: TableData) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [currentSetting, setCurrentSetting] = useState<SdkSetting>();

    return (
        <Container>
            <Table bordered responsive striped size="lg">
                {createHeaders(columns)}
                <tbody>
                    {mapSettings(data.settings, (setting) => {
                        setIsModalOpen(true);
                        setCurrentSetting(setting);
                    })}
                </tbody>
            </Table>
            {isModalOpen && currentSetting && 
                <SdkSettingsEditModal
                    onUpdate={data.onSettingUpdate}
                    setting={currentSetting}
                    onClose={() => {
                        setIsModalOpen(false);
                        setCurrentSetting(undefined);
                    }}
                />
            }
        </Container>
    )
};

export default SdkSettingsTable;

import { useEffect, useState } from "react";
import { CSCACertificate } from "../common/models/files-data";
import { Button, Col, Container, Form, FormGroup, Row, Table } from "reactstrap";
import { FileInput } from "../../common/components/file-input/FileInput";

interface CscaCertificatesProps {
    apiKey: string
    currentCertificates?: CSCACertificate[]
    onSubmit: (certificates: File) => void;
    onDelete: (certificates: CSCACertificate) => void;
}

export const CscaCertificates = (props:  CscaCertificatesProps) => {

    const [certificates, setCertificates] = useState<File|undefined>(undefined);

    const isFormValid = (): boolean => {
        return certificates !== undefined;
    }

    const resetForms = () => {
        setCertificates(undefined)
    }

    const onDelete = (certificate: CSCACertificate) => {
        props.onDelete(certificate)
    }

    const onUpload = () => {
        if (certificates !== undefined) {
            props.onSubmit(certificates)
            resetForms()
        }
    }

    useEffect(() => {
        resetForms()
    }, [props.apiKey])

    return (
        <Container>
            <Form>
                <Row form>
                    <Col sm={3}>
                        <FileInput label="Upload new CSCA Certificates File:" accept=".ldif" onFileSelected={setCertificates} />
                    </Col>
                    <Col sm={3} className="align-self-end">
                        <FormGroup>
                            <Button color="primary" disabled={!isFormValid()} onClick={() => onUpload()}>Upload</Button>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
            {props.currentCertificates &&
                <Table bordered responsive size="lg">
                    <thead>
                        <tr>
                            <th key={'date'} className={"sticky-begin"}>{"File"}</th>
                            <th key={'actions'} className={"table-last-col"}>{"Actions"}</th>
                        </tr>
                    </thead>
                {(props.currentCertificates.length === 0) &&
                    <tbody>
                       <tr>
                           <td colSpan={2}>No Data Available</td>
                        </tr>
                     </tbody>
                    }
                {props.currentCertificates.length > 0 &&
                        <tbody>
                            {props.currentCertificates.map((certificate, i) => (
                                <tr key={i}>
                                    <td key={i + '-date'} className={"sticky-begin"}>
                                        <div className={"table-col"}>{<a href={certificate.path} rel="noreferrer">{certificate.path}</a>}</div>
                                    </td>

                                    <td key={i + '- action'} className="sticky-end table-last-col">
                                        <div className={"table-last-col-content"}><Button color="default" onClick={() => onDelete(certificate)}><i className="fa fa-trash"></i></Button></div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    }
                 </Table>
            }
        </Container>
    );
}
import { useEffect, useState } from "react"
import { Button, Container, Spinner, Table } from "reactstrap"

import LogsService from "./services/logs.service"
import { Paginator } from "../common/components/paginator/Paginator"
import "./Logs.css";
import { PagedResult } from "../common/models/paged-result"
import { RouteComponentProps } from "react-router-dom"
import config from "../configs";
import Moment from 'moment'

export function Logs(props: RouteComponentProps) {
    const [data, setData] = useState<PagedResult<Date>|undefined>(undefined)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        function getPagedResources() {
            setLoading(true)
            LogsService.getPaged(props.location.search)
                .then((page) => {
                    setLoading(false)

                    setData(page)
                }, function(error) {
                    setLoading(false)
                    
                    setData(undefined)
                })
        }

        getPagedResources()
    }, [props.location.search])

    function formatDate(date: Date): string {
        return Moment(date).format(config.dateFormats.logsView)
    }

    function handlePageChange(page: number) {
        props.history.push(props.location.pathname + "?page=" + page);
    }

    function downloadLogs(date: Date) {
        setLoading(true)

        LogsService.downloadFilesForDate(date)
            .then(() => {
                setLoading(false)
            }, function(error) {
                setLoading(false)
            })
    }

    return (
        <Container>
            <Spinner color="primary" className={loading ? "visible" : "invisible"}></Spinner>
            {!loading &&
                <Container>
                    <Table bordered responsive size="lg">
                        <thead>
                            <tr>
                                <th key={'date'} className={"sticky-begin"}>{"Log Date"}</th>
                                <th key={'actions'} className={"table-last-col"}>{"Actions"}</th>
                            </tr>
                        </thead>
                        {(data === undefined || data.resources.length === 0) &&
                            <tbody>
                                <tr>
                                    <td colSpan={2}>No Data Available</td>
                                </tr>
                            </tbody>
                        }
                        {data && data.resources.length > 0 &&
                            <tbody>
                                {data.resources.map((row, i) => (
                                    <tr key={i}>
                                        <td key={i + '-date'} className={"sticky-begin"}>
                                            <div className={"table-col"}>{formatDate(row as Date)}</div>
                                        </td>

                                        <td key={i + '- action'} className="sticky-end table-last-col">
                                            <div className={"table-last-col-content"}><Button color="default" onClick={() => downloadLogs(row)}><i className="fa fa-download"></i></Button></div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        }
                    </Table>
                    {data && <Paginator pageCount={data.pageCount} changePage={(page: number) => handlePageChange(page)} currentPage={data.currentPage}></Paginator>}
                </Container>
            }
        </Container>
    )
}

import axios from '../../../common/api/custom-axios';
import { PagedResult } from '../../../common/models/paged-result';
import config from '../../../configs';
import { APIKeyDTO } from '../models/api-key-dto';
import { EditApiKeyRequest } from '../models/edit-api-key-request';
import { NewApiKeyRequest } from '../models/new-api-key-request';
import { APIKeyType } from '../models/api-key-type';

export class ApiKeyService {

    private apiKeyRelUri = "/api-key";

    getPaged(searchString: string, apiKeyType: APIKeyType = APIKeyType.Mobile): Promise<PagedResult<APIKeyDTO>> {
        return axios.get(`${config.endpoint}${this.apiKeyRelUri}?type=${apiKeyType}`).then(rsp => rsp.data); 
    }

    createApiKey(apiKey: NewApiKeyRequest): Promise<object> {
        return axios.post(config.endpoint + this.apiKeyRelUri, apiKey)
    }

    updateApiKey(token: string, apiKey: EditApiKeyRequest): Promise<object> {
        return axios.put(config.endpoint + this.apiKeyRelUri + "/" + token, apiKey);
    }

    deleteApiKey(token: string): Promise<object> {
        return axios.delete(config.endpoint + this.apiKeyRelUri + "/" + token);
    }
};

const apiKeyService = new ApiKeyService();

export default apiKeyService;
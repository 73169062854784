import { useEffect, useState } from "react";
import { Label } from "reactstrap";
import VersionService from "./VersionService";

const Version = () => {
    const [version, setVersion] = useState<string>("");

    useEffect(() => {
        getApiVersionAsync();
    }, []);
    
    const getApiVersionAsync = async () => {
        const res = await VersionService.getVersion();
        setVersion(res);
    }
    
    return <Label>{`Version: ${version}`}</Label>;
};

export default Version;
import { FC } from "react";
import { FormGroup, Label, FormFeedback } from "reactstrap";
import { DatePicker, IDatePicker } from "./DatePicker";

interface IDatePickerFormGroup extends IDatePicker {
    formFeedback: string;
    title: string;
}

const DatePickerFormGroup: FC<IDatePickerFormGroup> = (props) => (
    <FormGroup>
        <Label for="fromDate">{props.title}</Label>
        <DatePicker {...props} />
        <FormFeedback>{props.formFeedback}</FormFeedback>
    </FormGroup>
);

export default DatePickerFormGroup;

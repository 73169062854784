/* eslint-disable react-hooks/exhaustive-deps */
import { Route } from 'react-router';
import './App.css';
import { Layout } from './common/components/layout/Layout';
import { ApiKeyList } from './api-key/list/ApiKeyList';
import SupportFiles from './support-files/SupportFiles';
import Reports from './reports/list/Reports';
import ReportDetail from './reports/detail/ReportDetail';
import { TermsAndConditions } from "./terms-conditions/TermsAndConditions";
import { Logs } from "./logs/Logs";
import routes from './routes';
import { Redirect, Switch } from 'react-router-dom';
import SdkSettings from './sdk-settings/SdkSettings';
import ApiSettings from './api-settings/ApiSettings';
import WithAuth from './common/authentication/WithAuth';
import Health from './health/Health';
import Transactions from './transactions/Transactions';
import TransactionDetail from './transactions/TransactionDetail';

const App = () => {
  return (
    <Switch>
      <Route exact path={routes.health} component={Health} />
      <WithAuth>
        <Layout>
          <Route exact path={routes.home} component={ApiKeyList} />

          <Route exact path={routes.apiKeys} component={ApiKeyList} />

          <Route exact path={routes.consumerKeys} component={ApiKeyList} />
          <Route exact path={routes.supportFiles} component={SupportFiles} />
          <Route exact path={routes.reports} component={Reports} />
          <Route exact path={`${routes.reportDetail}/:feature`} component={ReportDetail} />
          <Route exact path={routes.terms} component={TermsAndConditions} />
          <Route exact path={routes.logs} component={Logs} />
          <Route exact path={routes.sdkSettings} component={SdkSettings} />
          <Route exact path={routes.apiSettings} component={ApiSettings} />
          <Route exact path={routes.transactions} component={Transactions} />
          <Route exact path={`${routes.transactions}/:feature`} component={TransactionDetail} />
          <Redirect to={routes.home} />
        </Layout>
      </WithAuth>
    </Switch>
  )
}

export default App;

import config from "../configs";
import axios from "../common/api/custom-axios";
import { deviceOS } from "../common/models/os";
import { PagedResult } from '../common/models/paged-result';

export interface TransactionFiltersData {
  apiKeys: ClientData[],
  sdkVersions: string[],
  deviceOS: deviceOS[],
}

export interface ClientData {
  apiKeyName: string,
  apiKeyToken: string
}

interface TransactionSummarySearchQueryParams {
  apiKey: string;
  from?: Date;
  to?: Date;
  sdkVersion?: string;
  os?: deviceOS;
}

export interface TransactionSummary {
  feature: string;
  count: number;
}

export interface TransactionDetail {
  createdAt: string;
  deviceBrand: string;
  deviceId: string;
  deviceModel: string;
  deviceOS: string;
  sdkVersion: string;
}

const transactionsRelUri = "transactions";
const transactionFiltersRelUri = "filters";
const transactionsDetailRelUri = "detail";
const transactionsExportRelUri = "export";

const getFilters = async (): Promise<TransactionFiltersData> => {
    const res = await axios.get(`${config.endpoint}/${transactionsRelUri}/${transactionFiltersRelUri}`);
    return res.data;
};


const getSummary = async (request: TransactionSummarySearchQueryParams, page: number = 0): Promise<PagedResult<TransactionSummary>> => {
  const res = await axios.get<PagedResult<TransactionSummary>>(
      `${config.endpoint}/${transactionsRelUri}`, {
          params: {
              to: request.to,
              from: request.from,
              apiKey: request.apiKey,
              sdkVersion: request.sdkVersion,
              os: request.os,
              page,
          }
      }
  );

  return res.data;
}

const getDetail = async (featureId: string, request: TransactionSummarySearchQueryParams, page: number = 0): Promise<PagedResult<TransactionDetail>> => {
  const res = await axios.get<PagedResult<TransactionDetail>>(
      `${config.endpoint}/${transactionsRelUri}/${transactionsDetailRelUri}/${featureId}`, { 
          params: {
              to: request.to,
              from: request.from,
              apiKey: request.apiKey,
              sdkVersion: request.sdkVersion || null,
              deviceOS: request.os || null,
              page,
          }
      });

  return res.data;
}

const getCSV = async (request: TransactionSummarySearchQueryParams): Promise<Blob> => {
  const res = await axios.get<Blob>(
    `${config.endpoint}/${transactionsRelUri}/${transactionsDetailRelUri}/${transactionsExportRelUri}`, { 
        params: {
            to: request.to,
            from: request.from,
            apiKey: request.apiKey,
            sdkVersion: request.sdkVersion || null,
            deviceOS: request.os || null,
        },
        responseType: "blob",
    });

    return res.data;
}

const service = {
  getFilters,
  getSummary,
  getDetail,
  getCSV,
};

export default service;
import { UserManager } from "oidc-client-ts";
import configs from "../../configs";

const authenticator: UserManager = new UserManager({
  authority: configs.authority || "",
  client_id: configs.client_id,
  redirect_uri: window.location.href,
  post_logout_redirect_uri: window.location.href,
  scope: configs.scope,
  response_type: configs.response_type,
  metadata: {
      authorization_endpoint: configs.authorization_endpoint,
      token_endpoint: configs.token_endpoint,
      end_session_endpoint: configs.logout_endpoint
  },
  automaticSilentRenew: false,
});

export default authenticator;
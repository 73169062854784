import { PagedResult } from '../../../common/models/paged-result';
import config from '../../../configs';
import { TransactionSummary } from '../models/transaction-summary-dto';
import { ReportFiltersData } from "../models/filters-data";
import { ReportDetailFiltersData } from "../models/detail-filters-data";
import { ReportsSearchRequest } from "../models/reports-search-request";
import { ReportsDetailSearchRequest } from "../models/reports-detail-search-request";
import axios from '../../../common/api/custom-axios';
import { PagedResultTransactionDetails } from '../models/paged-result-transaction-details';

export class ReportsService {

    private reportsRelUri = "reports";
    private reportFiltersRelUri = "filters";
    private reportDetailRelUri = "detail";
    private reportExportRelUri = "export";

    async getFilters(): Promise<ReportFiltersData> {
        const res = await axios.get(`${config.endpoint}/${this.reportsRelUri}/${this.reportFiltersRelUri}`);
        return res.data;
    }

    async get(request: ReportsSearchRequest, page: number = 0): Promise<PagedResult<TransactionSummary>> {
        const res = await axios.get<PagedResult<TransactionSummary>>(
            `${config.endpoint}/${this.reportsRelUri}`, {
                params: {
                    to: request.to,
                    from: request.from,
                    apiKey: request.apiKey,
                    sdkVersion: request.sdkVersion || null,
                    page,
                }
            }
        );

        return res.data;
    }

    async detail(featureId: string, request: ReportsDetailSearchRequest, page: number = 0): Promise<PagedResultTransactionDetails> {
        const res = await axios.get<PagedResultTransactionDetails>(
            `${config.endpoint}/${this.reportsRelUri}/${this.reportDetailRelUri}/${featureId}`, { 
                params: {
                    to: request.to,
                    from: request.from,
                    apiKey: request.apiKey,
                    sdkVersion: request.sdkVersion || null,
                    deviceOS: request.deviceOS || null,
                    deviceModel: request.deviceModel || null,
                    deviceBrand: request.deviceBrand || null,
                    status: request.status || null,
                    page,
                }
            });

        return res.data;
    }

    async getDetailFilters(featureId: string, request: ReportsDetailSearchRequest): Promise<ReportDetailFiltersData> {
        const res = await axios.get(
            `${config.endpoint}/${this.reportsRelUri}/${this.reportDetailRelUri}/${featureId}/${this.reportFiltersRelUri}`, {
                params: {
                    to: request.to,
                    from: request.from,
                    apiKey: request.apiKey,
                    sdkVersion: request.sdkVersion || null,
                    deviceOS: request.deviceOS || null,
                    deviceModel: request.deviceModel || null,
                    deviceBrand: request.deviceBrand || null,
                    status: request.status || null,
                }
            });

        return res.data;
    }

    async getCSV(request: ReportsSearchRequest): Promise<Blob> {
        const res = await axios.get<Blob>(
            `${config.endpoint}/${this.reportsRelUri}/${this.reportDetailRelUri}/${this.reportExportRelUri}`, {
                params: {
                    to: request.to,
                    from: request.from,
                    apiKey: request.apiKey,
                    sdkVersion: request.sdkVersion || null,
                },
                responseType: "blob",
            }
        );

        return res.data;        
    }
};

const reportsService = new ReportsService();

export default reportsService;

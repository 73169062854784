import { Component } from 'react';
import { Container, Toast, ToastBody, ToastHeader } from 'reactstrap';
import { NavMenu } from '../nav-menu/NavMenu';
import ToastService, { emptyToastState, ToastState } from '../../services/toast-service';
import "./Layout.css"
import { Role } from '../../../user/roles';
import UserService from '../../../user/service/user-service';

interface LayoutState {
  toastState: ToastState,
  userRoles: Role[]
}

export class Layout extends Component<{}, LayoutState> {

  constructor(props: object) {
    super(props);

    this.state = {
      toastState: emptyToastState,
      userRoles: []
    }
  }

  componentDidMount() {
    ToastService.showToast$.subscribe(toastState => {
      this.setState({toastState: toastState});
    });

    UserService.getPermissions()
      .then(data => this.setState({userRoles: data}))
      .catch(_ => ToastService.showToast("Error", "Could not authenticate user"));
  }

  render () {
    return (
      <div>
        <NavMenu roles={this.state.userRoles}/>
        <Toast isOpen={this.state.toastState.show} className={this.state.toastState.success === true ? "success" : "error"}>
          <ToastHeader toggle={() => this.toggle()}>{this.state.toastState.title}</ToastHeader>
          <ToastBody>
            {this.state.toastState.message}
          </ToastBody>
        </Toast>
        <Container>
          {this.props.children}
        </Container>
      </div>
    );
  }

  toggle() {
    this.setState({toastState: {...this.state.toastState, show: false}})
  }
}

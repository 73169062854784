import { reportStatus } from "../../common/models/reportStatus";

const statusList = {
    SUCCESS: "Success",
    ERROR: "Failure",
    WARNING: "Warning"
};

export const buildStatusList = (status: reportStatus[]) => {
    const list = status.map(s => ({ text: statusList[s], value: s.toString() }));
    list.unshift({ text: "All", value: ""});
    return list;
}

export const transformArrayToSelectorsList = (array: string[]) => {
    let list = array.map(v => ({ text: v, value: v }));

    if (list) {
        list = list.filter(x => x.value !== "");
        list.unshift({ text: "All", value: ""});
        return list;
    }
    
    return [];
}


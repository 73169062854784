/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Container, Form, Label, Row, Spinner } from "reactstrap";
import ToastService from "../../common/services/toast-service";
import { ReportDetailFiltersData } from "../common/models/detail-filters-data";
import { ReportsSearchRequest } from "../common/models/reports-search-request";
import { ReportsDetailSearchRequest } from "../common/models/reports-detail-search-request";
import ReportsService from "../common/services/reports-service";
import { ButtonForm, Paginator, SelectorFormGroup } from "../../common/components";
import TransactionDetailList from "./components/TransactionDetailList";
import { columns, buildStatusList, transformArrayToSelectorsList } from "./util";
import "../common/Reports.css";
import { deviceOS } from "../../common/models/os";
import { reportStatus } from "../common/models/reportStatus";
import { PagedResultTransactionDetails } from "../common/models/paged-result-transaction-details";

interface IDetailFilters {
    status?: reportStatus;
    deviceModel: string;
    deviceOS: deviceOS | undefined;
    deviceBrand: string;
}

const ReportDetail: FC = () => {
    const history = useHistory();
    const location = history.location;
    const [filtersData, setFiltersData] = useState<ReportDetailFiltersData>();
    const [data, setData] = useState<PagedResultTransactionDetails>();
    const [filters, setFilters] = useState<IDetailFilters>();

    const getLastItem = (thePath: string) => thePath.substring(thePath.lastIndexOf("/") + 1);

    const getSearchParams = (): ReportsSearchRequest => {
        const query = new URLSearchParams(location.search);

        return {
            apiKey: query.get("apiKey") || "",
            sdkVersion: query.get("sdkVersion") || "",
            to: query.get("toDate") ? new Date(query.get("toDate")!) : null,
            from: query.get("fromDate") ? new Date(query.get("fromDate")!) : null
        };
    };

    const resetFilters = () => setFilters({
        status: undefined,
        deviceModel: "",
        deviceOS: undefined,
        deviceBrand: ""
    });

    const setupFilters = async () => {
        const params = getSearchParams();

        if (params.apiKey === null || params.apiKey === undefined) {
            return;
        }

        const featureId = getLastItem(location.pathname);

        const request: ReportsDetailSearchRequest = {
            apiKey: params.apiKey,
            sdkVersion: params.sdkVersion,
            from: params.from,
            deviceBrand: filters?.deviceBrand || "",
            to: params.to,
            deviceModel: filters?.deviceModel || "",
            deviceOS: filters?.deviceOS,
            status: filters?.status
        }

        try {
            const res = await ReportsService.getDetailFilters(featureId, request);   
            setFiltersData(res);         
        } catch (error: any) {
            ToastService.showToast("Error fetching detail filters", error.response.data.detail);            
        }
    };

    const submitSearch = async (page: number = 0) => {
        const params = getSearchParams();

        if (params.apiKey === null || params.apiKey === undefined) {
            return;
        }

        const request: ReportsDetailSearchRequest = {
            apiKey: params.apiKey,
            sdkVersion: params.sdkVersion,
            from: params.from,
            deviceBrand: filters?.deviceBrand || "",
            to: params.to,
            deviceModel: filters?.deviceModel || "",
            deviceOS: filters?.deviceOS,
            status: filters?.status
        }

        const featureId = getLastItem(location.pathname);
        
        try {
            const res = await ReportsService.detail(featureId, request, page);
            setData(res);
        } catch (error: any) {
            ToastService.showToast("Error applying detail filters.", error.response.data.detail);            
        }
    };

    const changePage = (page: number) => {
        const query = new URLSearchParams(location.search);

        if (query.has("page")) {
            query.set("page", page.toString());
        }
        else {
            query.append("page", page.toString());
        }

        history.push(`?${query.toString()}`);
    };

    useEffect(() => {
        setupFilters();
    }, []);

    useEffect(() => {
        if (location.search) {
            const params = new URLSearchParams(location.search);
            const page = parseInt(params.get("page") || "0");
            submitSearch(page);
        }
    }, [location]);

    useEffect(() => {
        if (filters) {
            submitSearch();
        }
    }, [filters]);

    const buildForm = () => {
        return (
            <Form>
                <Row form>
                    <Col sm={2}>
                        <SelectorFormGroup 
                            title="Status"
                            id="statusSelector"
                            onChange={(status) => setFilters(f => ({
                                deviceModel: f?.deviceModel || "",
                                deviceOS: f?.deviceOS,
                                deviceBrand: f?.deviceBrand || "",
                                status: status as reportStatus
                            }))}
                            values={filtersData?.status ? buildStatusList(filtersData.status) : []}
                            value={filters?.status || ""}
                        />
                    </Col>
                    <Col sm={2}>
                        <SelectorFormGroup 
                            title="Device Brand"
                            id="deviceBrandSelector"
                            onChange={(deviceBrand) => setFilters(f => ({
                                deviceModel: f?.deviceModel || "",
                                status: f?.status,
                                deviceOS: f?.deviceOS,
                                deviceBrand
                            }))}
                            values={transformArrayToSelectorsList(filtersData?.deviceBrands || [])}
                            value={filters?.deviceBrand || ""}
                        />
                    </Col>                    
                    <Col sm={2}>
                        <SelectorFormGroup 
                            title="Device Model"
                            id="deviceModelSelector"
                            onChange={(deviceModel) => setFilters(f => ({
                                deviceModel,
                                deviceOS: f?.deviceOS,
                                deviceBrand: f?.deviceBrand || "",
                                status: f?.status
                            }))}
                            values={transformArrayToSelectorsList(filtersData?.deviceModels || [])}
                            value={filters?.deviceModel || ""}
                        />
                    </Col>
                    <Col sm={2}>
                        <SelectorFormGroup 
                            title="Device OS"
                            id="deviceOsSelector"
                            onChange={(deviceOS) => setFilters(f => ({
                                deviceModel: f?.deviceModel || "",
                                status: f?.status,
                                deviceBrand: f?.deviceBrand || "",
                                deviceOS: deviceOS as deviceOS
                            }))}
                            values={filtersData?.deviceOS ? transformArrayToSelectorsList(filtersData.deviceOS): []}
                            value={filters?.deviceOS || ""}
                        />
                    </Col>
                    <Col className="align-self-end col-md-auto">
                        <ButtonForm text="Reset" onClick={resetFilters} importance="secondary" />
                    </Col>
                </Row>
                <Row className="no-gutters">
                    <Col sm={2}>
                        <Label for="totalDevices">Total Devices: </Label>
                        <Label for="totalDevices" className="total-value">{data?.totalDevices || 0}</Label>
                    </Col>
                    <Col sm={2}>
                        <Label for="totalTransactions">Total Transactions: </Label>
                        <Label for="totalTransactions" className="total-value">{data?.rowCount || 0}</Label>
                    </Col>
                </Row>                
            </Form>
        )
    }

    return !!filtersData && data ?
        (<Container>
            <Container>{buildForm()}</Container>
            <Container>
                <TransactionDetailList columns={columns} result={data} />
                <Paginator pageCount={data.pageCount} changePage={changePage} currentPage={data.currentPage} />
            </Container> 
        </Container>) :
        (<Container>
            <Spinner color="primary"/>
        </Container>);
}

export default ReportDetail;
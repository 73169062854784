import { PagedResult } from "../../../common/models/paged-result";
import { TransactionDetailSummary } from "../../common/models/transaction-detail-summary-dto";
import { Table } from "reactstrap";
import { ColumnDef } from '../util';

const TransactionDetailList = (props: { columns: ColumnDef[], result: PagedResult<TransactionDetailSummary>}) => {

    return <Table bordered responsive striped size="lg">
        <thead>
            <tr>
                {props.columns.map(column => (<th key={column.accessor}>{column.header}</th>))}
            </tr>
        </thead>
        {props.result.resources.length === 0 &&
            <tbody>
                <tr>
                    <td colSpan={(props.columns.length + 1)}>No Data Available</td>
                </tr>
            </tbody>
        }
        {props.result.resources.length > 0 &&
            <tbody>
                {props.result.resources.map((row, i) => (
                    <tr key={i}>
                        {props.columns.map(column => (
                            <td key={i + '-' + column.accessor}>
                                {(row as { [key: string]: any })[column.accessor] || ""}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        }
    </Table>
}

export default TransactionDetailList;


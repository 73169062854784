import { FC } from "react";
import { Input } from "reactstrap";

export interface IDatePicker {
    maxValue?: Date;
    minValue?: Date;
    onChange: (date: string) => any;
    id: string;
    localeDateString?: string;
    value: Date | undefined;
}

export const DatePicker: FC<IDatePicker> = (props) => (
    <Input
        type="date"
        name={props.id}
        onChange={(e) => props.onChange(e.target.value)}
        max={props.maxValue?.toLocaleDateString(props.localeDateString || "sv-SE")}
        min={props.minValue?.toLocaleDateString(props.localeDateString || "sv-SE")}
        value={props.value?.toLocaleDateString(props.localeDateString || "sv-SE")}
    />
);

import { AxiosInstance } from 'axios';
import authenticator from './authenticator';

export function setupAxiosAuthInterceptor(axios: AxiosInstance): AxiosInstance {
    axios.interceptors.request.use(async config => {
        const user = await authenticator.getUser();

        if (user && user.access_token) {
            config.headers['Authorization'] = `Bearer ${user.access_token}`;
        }

        return config;
    });

    return axios;
}


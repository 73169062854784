/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Col, Container, Form, Row, Spinner, Table, NavLink } from "reactstrap";
import { ButtonForm, DatePickerFormGroup, DownloadButton, Paginator, SelectorFormGroup } from "../common/components";
import { deviceOS } from "../common/models/os";
import { PagedResult } from "../common/models/paged-result";
import ToastService from "../common/services/toast-service";
import routes from "../routes";
import TransactionsService, { TransactionFiltersData, TransactionSummary } from "./transactions-service";

interface IFilters {
  apiKey: string;
  fromDate: string;
  toDate: string;
  sdkVersion: string;
  os?: deviceOS;
}

const Transactions: FC = () => {
  const [filtersToQuery, setFiltersToQuery] = useState<IFilters>({ 
    apiKey: sessionStorage.getItem("apiKey_transactions") || "",
    fromDate: sessionStorage.getItem("fromDate_transactions") || "",
    toDate: sessionStorage.getItem("toDate_transactions") || "",
    sdkVersion: sessionStorage.getItem("sdkVersion_transactions") || ""
  });
  const [filters, setFilters] = useState<TransactionFiltersData>();
  const [data, setData] = useState<PagedResult<TransactionSummary>>();
  const [isSearching, setIsSearching] = useState<boolean>();
  const history = useHistory();

  const getFilters = async () => {
    const filters = await TransactionsService.getFilters();
    setFilters(filters);
  }

  const getSummaries = async (page: number) => {
    try {
      setData(undefined);
      setIsSearching(true);

      const summaries = await TransactionsService.getSummary(getServiceFilters(), page);

      setIsSearching(false);
      setData(summaries);
    } catch (error: any) {
      ToastService.showToast("Error applying search filters", error);
      setIsSearching(false);
    }
  };

  const downloadTransactions = async () => {
    try {
      const transactions = await TransactionsService.getCSV(getServiceFilters());
      ToastService.showToast("File downloaded.", "File downloaded to your Downloads folder.", true);
      return transactions;
    } catch (error: any) {
      if (error.response.status === 403) {
        ToastService.showToast("Error downloading file.", "The requested data is too large. Try adjusting the filters.");
      } else {
        ToastService.showToast("Error downloading file.", "");
      }

      throw error;
    }
  };

  useEffect(() => {
      getFilters();
  }, []);

  useEffect(() => {
    Object.entries(filtersToQuery).forEach(x => x[1] && sessionStorage.setItem(`${x[0]}_transactions`, x[1]));
  }, [filtersToQuery]);

  useEffect(() => {
    if (filtersToQuery.apiKey) {
      const params = new URLSearchParams(history.location.search);
      const page = parseInt(params.get("page") || "0");
      getSummaries(page);
    } else {
      setData(undefined);
    }
  }, [filtersToQuery]);

  useEffect(() => {
    if (history.location.search && filtersToQuery.apiKey) {
        const params = new URLSearchParams(history.location.search);
        const page = parseInt(params.get("page") || "0");
        getSummaries(page);
    }
  }, [history.location]);
  
  const resetFilters = () => {
    setFiltersToQuery({ apiKey: "", fromDate: "", toDate: "", sdkVersion: "", os: undefined });
  };

  const getServiceFilters = () => {
    const fromDate = filtersToQuery.fromDate ? new Date(filtersToQuery.fromDate) : undefined;
    const toDate = filtersToQuery.toDate ? new Date(filtersToQuery.toDate) : undefined;
    fromDate?.setUTCHours(0, 0, 0, 0);
    toDate?.setUTCHours(23, 59, 59, 999);

    return {
      apiKey: filtersToQuery.apiKey,
      from: fromDate,
      to: toDate,
      os: filtersToQuery.os as deviceOS || undefined,
      sdkVersion: filtersToQuery.sdkVersion || undefined,
    };
  }

  const changePage = (page: number) => history.push(history.location.pathname + "?page=" + page);

  const transformApiKeyList = () => {
    const list = filters?.apiKeys.map(c => ({
        text: c.apiKeyName,
        value: c.apiKeyToken
    }));

    if (list) {
        list.unshift({ text: "", value: ""});
        return list;
    }
    
    return [];
  }

  const transformList = (values: string[]) => {
      const list = values.map(v => ({ text: v, value: v }));

      if (list) {
          list.unshift({ text: "All", value: ""});
          return list;
      }

      return [];
  };

  const buildSearchQuery = (featureType: string) => {
    const params = {
        from: filtersToQuery.fromDate,
        to: filtersToQuery.toDate,
        apiKey: filtersToQuery.apiKey,
        sdkVersion: filtersToQuery.sdkVersion,
        deviceOS: filtersToQuery.os,
    } as {[key: string]: any};

    var query = Object.keys(params).filter(key => !!(params[key])).map(key => key + '=' + params[key]).join('&');
    return `${routes.transactions}/${featureType}?${query}`;
  };

  const getFileName = (): string => {
    const apiKeyName = filters?.apiKeys.find(a => a.apiKeyToken === filtersToQuery.apiKey)?.apiKeyName;
    const date = (new Date()).toISOString();
    return `transactions.${apiKeyName}.${date}.csv`;
  }

  const buildForm = () => (
    <Form>
      <Row form>
        <Col sm={3}>
          <SelectorFormGroup 
            title="Client" 
            id="apiKeySelect"
            values={transformApiKeyList()} 
            onChange={(value) => setFiltersToQuery(s => ({ ...s, apiKey: value }))}
            value={filtersToQuery.apiKey}
          />
        </Col>
        <Col sm={1}>
          <SelectorFormGroup 
            title="Version" 
            id="versionSelect" 
            values={filters?.sdkVersions ? transformList(filters?.sdkVersions) : []}
            onChange={(value) => setFiltersToQuery(s => ({ ...s, sdkVersion: value }))}
            value={filtersToQuery.sdkVersion}
          />
        </Col>
        <Col sm={1}>
          <SelectorFormGroup 
            title="OS" 
            id="osSelect" 
            values={filters?.deviceOS ? transformList(filters?.deviceOS) : []}
            onChange={(value) => setFiltersToQuery(s => ({ ...s, os: value as deviceOS }))}
            value={filtersToQuery.os || ""}
          />
        </Col>
        <Col sm={2}>
          <DatePickerFormGroup 
            formFeedback="This field is required"
            id="fromDate"
            title="From"
            onChange={(value) => setFiltersToQuery(s => ({ ...s, fromDate: value }))}
            maxValue={filtersToQuery.toDate ? new Date(filtersToQuery.toDate) : new Date()}
            value={new Date(filtersToQuery.fromDate)}
          />
        </Col>
        <Col sm={2}>
          <DatePickerFormGroup 
            formFeedback="This field is required"
            id="toDate"
            title="To"
            onChange={(value) => setFiltersToQuery(s => ({ ...s, toDate: value }))}
            maxValue={new Date()}
            minValue={filtersToQuery.fromDate ? new Date(filtersToQuery.fromDate) : undefined}
            value={new Date(filtersToQuery.toDate)}
          />
        </Col>
        <Col className="align-self-end col-md-auto">
          <ButtonForm text="Reset" onClick={resetFilters} importance="secondary" />
        </Col>
        <Col className="align-self-end col-md-auto">
          <DownloadButton 
            text="Export Data"
            fetchFile={() => downloadTransactions()}
            importance="primary"
            disabled={!!!data}
            fileName={getFileName()}
          />
        </Col>        
      </Row>
    </Form>
  );

  const buildTable = () => (
    <Table bordered responsive striped size="lg">
      <thead>
        <tr>
          <th key="feature-header" style={{width: "85%"}}>Feature</th>
          <th key="total-header">Total</th>
        </tr>
      </thead>
      <tbody>
        { 
          !data || data.resources.length === 0 ?
            <tr>
              <td colSpan={2}>No Data Available</td>
            </tr> :
          data.resources.map((row, i) => (
            <tr>
              <td key="feature">
                <NavLink tag={Link} to={() => buildSearchQuery(row.feature)}>{row.feature}</NavLink>
              </td>
              <td key="total-count">
                {row.count}
              </td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  );

  return (
    <Container>
        <Container>{!!!filters ? <Spinner color="primary"/> : buildForm()}</Container>
      <Container>
      {data?.resources ?
        <Container>
            {buildTable()}
            <Paginator 
              pageCount={data.pageCount}
              changePage={changePage}
              currentPage={data.currentPage}
            />
        </Container> :
        isSearching && <Spinner color="primary" />
      }        
      </Container>
    </Container>
  );
};

export default Transactions;

import { Component } from "react";
import { Button, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { FormField } from "../../common/components/form-field/FormField";
import { APIKeyDTO } from "../common/models/api-key-dto";
import { EditApiKeyRequest } from "../common/models/edit-api-key-request";
import "./ApiKeyEditModal.css";
import config from "../../configs";
import Moment from 'moment';
import { APIKeyType } from '../common/models/api-key-type';

interface ApiKeyEditProps {
    isOpen: boolean;
    apiKey: APIKeyDTO;
    type: APIKeyType;

    toggle: () => void;
    submit: (token: string, apiKey: EditApiKeyRequest) => void;
    delete: (token: string) => void;
};

interface ApiKeyEditState {
    name?: string;
    description?: string;
    expiryDate?: string;
    permissions?: {[permission: string]: boolean};
    type: APIKeyType;
}

const emptyState: ApiKeyEditState = {
    name: undefined,
    description: undefined,
    expiryDate: undefined,
    permissions: undefined,
    type: APIKeyType.Mobile
}

export class ApiKeyEditModal extends Component<ApiKeyEditProps, ApiKeyEditState> {

    constructor(props: ApiKeyEditProps) {
        super(props);
        this.state = {...emptyState, type: this.props.type};
    }

    render() {
        return (
            <Container>
            {this.props.isOpen &&
                <Modal isOpen={this.props.isOpen} toggle={() => this.props.toggle()} size="md" onClosed={() => this.resetState()}>
                    <ModalHeader toggle={() => this.props.toggle()}>Edit Api Key</ModalHeader>
                    <ModalBody>
                        {this.props.apiKey &&
                        <Form>
                            <Container>
                                <Row>
                                    <Col>
                                        <FormField
                                            label="Name"
                                            type="text"
                                            initialValue={this.props.apiKey.name}
                                            isValid={() => this.isNameValid()}
                                            onChange={val => this.setState({name: val ?? ""})}
                                            required />
                                        <FormField
                                            label="Description"
                                            type="text"
                                            initialValue={this.props.apiKey.description}
                                            onChange={val => this.setState({description: val ?? ""})} />
                                        <FormField
                                            label="Expiry Date"
                                            type="date"
                                            initialValue={this.formatDate(this.props.apiKey.validUntil)}
                                            isValid={() => this.isExpiryDateValid()}
                                            onChange={val => this.setState({expiryDate: val ?? ""})}
                                            required />
                                    </Col>
                                    { this.state.type === APIKeyType.Mobile &&
                                        <Col className="permissions-column">
                                            {Object.keys(this.props.apiKey.permissions).map((permission) =>
                                                <FormGroup>
                                                <Label for={permission}>
                                                    <Input
                                                        type="checkbox"
                                                        name={permission}
                                                        defaultChecked={this.props.apiKey.permissions[permission]}
                                                        onChange={e => this.handlePermissionChange(permission, e.target.checked)}
                                                    ></Input>
                                                    {permission.toLocaleUpperCase()}
                                                </Label>
                                                </FormGroup>
                                            )}
                                        </Col>
                                    }
                                </Row>
                            </Container>
                        </Form>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => this.deleteApiKey()}>Delete</Button>
                        <Button color="primary" disabled={!this.hasChanged() || !this.isFormValid()} onClick={() => this.submit()}>Update</Button>
                        <Button color="secondary" onClick={() => this.toggle()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            }
            </Container>
        );
    }

    private handlePermissionChange(permission: string, value: boolean) {
        var newPermissions: {[permission: string]: boolean};
        if (this.state.permissions) {
            newPermissions = this.state.permissions;
        } else {
            newPermissions = {};
        }
        newPermissions[permission] = value;
        this.setState({permissions: newPermissions});
    }

    private submit() {
        if (this.hasChanged()) {
            var editApiKeyRequest: EditApiKeyRequest = {
                name: this.state.name ?? this.props.apiKey.name,
                description: this.state.description ?? this.props.apiKey.description,
                expiryDate: this.state.expiryDate ?? this.props.apiKey.validUntil,
                permissions: Object.assign({}, this.props.apiKey.permissions, this.state.permissions) 
            }
            this.props.submit(this.props.apiKey.token, editApiKeyRequest);
        }
        this.toggle();
    }

    private deleteApiKey() {
        this.props.delete(this.props.apiKey.token);
        this.toggle();
    }

    private toggle() {
        this.props.toggle();
    }

    private resetState() {
        this.setState(emptyState);
    }

    private hasChanged(): boolean {
        if (this.state.name) {
            if (this.state.name !== this.props.apiKey.name) {
                return true;
            }
        }
        if (this.state.description) {
            if (this.state.description !== this.props.apiKey.description) {
                return true;
            }
        }
        if (this.state.expiryDate) {
            if (this.state.expiryDate !== this.props.apiKey.validUntil) {
                return true;
            }
        }
        if (this.state.permissions) {
            for(var permission in this.state.permissions) {
                if (this.state.permissions[permission] !== this.props.apiKey.permissions[permission]) {
                    return true;
                }
            }
        }
        return false;
    }

    private formatDate(date: string): string {
        var dateObj = new Date(date);
        return Moment(dateObj).format(config.dateFormats.apiKeyEditModalView)
    }

    private isFormValid(): boolean {
        return this.isNameValid() && this.isExpiryDateValid();
    }

    private isNameValid(): boolean {
        return this.state.name?.replace(/\s/g,'') !== '';
    }

    private isExpiryDateValid(): boolean {
        return this.state.expiryDate !== ''
    }
}
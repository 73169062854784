import { Subject } from "rxjs";

const ERROR_DURATION = 20; // seconds
const SUCCESS_DURATION = 3; // seconds

export interface ToastState {
    show: boolean;
    title: string;
    message: string;
    success: boolean;
}

export const emptyToastState: ToastState = {
    show: false,
    title: "",
    message: "",
    success: false
}

export class ToastService {
    
    showToast$ = new Subject<ToastState>();

    showToast(title: string, message: string, success = false) {
        this.showToast$.next({show: true, title: title, message: message, success: success});

        setTimeout(() => {
            this.showToast$.next({...emptyToastState, success: success});
        }, (success ? SUCCESS_DURATION : ERROR_DURATION) * 1000);
    }
}

const service = new ToastService();

export default service;
const configs = {
	'endpoint': `${process.env.REACT_APP_API_URI}/backoffice`,
	'defaultLanguage': 'en-GB',
	'dateFormats': {
		'apiKeyListView': 'YYYY-MM-DD',
		'apiKeyEditModalView': 'YYYY-MM-DD',
		'logsView': 'YYYY-MM-DD',
		'logFileDownloadRequest': 'YYYY-MM-DD'
	},
	
	// AUTH
	'authority': process.env.REACT_APP_IDENTITY_SERVER_URI,
	'client_id': 'mobile-id',
	'scope': 'openid',
	'response_type': 'code',
	'authorization_endpoint': `${process.env.REACT_APP_IDENTITY_SERVER_URI}/realms/master/protocol/openid-connect/auth`,
	'token_endpoint': `${process.env.REACT_APP_IDENTITY_SERVER_URI}/realms/master/protocol/openid-connect/token`,
	'logout_endpoint': `${process.env.REACT_APP_IDENTITY_SERVER_URI}/realms/master/protocol/openid-connect/logout`
};

export default configs;

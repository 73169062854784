import { FC } from "react";
import { Button } from "reactstrap";

interface IButton {
    text: string;
    onClick: () => any;
    importance: "primary" | "secondary";
    disabled?: Boolean;
}

const ButtonForm: FC<IButton> = (props) => (
    <Button className="search-button" color={props.importance} onClick={props.onClick} disabled={!!props.disabled}>
        {props.text}
    </Button>
);

export default ButtonForm;

import axios from "../common/api/custom-axios";
import configs from "../configs";

export enum ApiSettingsType {
    String = "String",
    Integer = "Integer",
    Decimal = "Decimal",
    Boolean = "Boolean"
}

export interface FiltersData {
    apiKey: ClientData[]
}

export interface ClientData {
    token: string,
    name: string,
}

export interface ApiSetting {
    id: number,
    apiKey: string,
    feature: string,
    key: string,
    description: string,
    value: string,
    type: ApiSettingsType,
}

const getFilters = async (): Promise<FiltersData> => {
    const res = await axios.get<FiltersData>(`${configs.endpoint}/api-settings/filters`);
    return res.data;
};

const queryData = async (apiKey?: string): Promise<ApiSetting[]> => {
    const res = await axios.get<ApiSetting[]>(`${configs.endpoint}/api-settings`, {
        params: {
            apiKey,
        }
    });

    return res.data;
}

const updateSetting = async (setting: ApiSetting): Promise<void> => {
    await axios.put(`${configs.endpoint}/api-settings/${setting.id}`, {
        value: setting.value
    });
}

const service = {
    queryData,
    updateSetting,
    getFilters
}

export default service;
import { CustomInput, FormGroup, Label } from "reactstrap"

export interface FileInputProps {
    label: string
    accept?: string
    onFileSelected: (file: File|undefined) => void
}

export const FileInput = (props: FileInputProps) => {

    return (
        <FormGroup>
            <Label for={props.label}>{props.label}</Label>
            <CustomInput accept={props.accept} type="file" id={props.label} name={props.label}
                onChange={e => props.onFileSelected(e.target.files?.[0])} />
        </FormGroup>
    )
}
import { FC } from "react";
import { FormGroup, Label } from "reactstrap";
import { ISelector, Selector } from "./Selector";

export interface ISelectorFormGroup extends ISelector {
    title: string;
}

const SelectorFormGroup: FC<ISelectorFormGroup> = (props) => (
    <FormGroup>
        <Label>{props.title}</Label>
        <Selector {...props} />
    </FormGroup>
);

export default SelectorFormGroup;

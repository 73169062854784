/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Container, Spinner } from "reactstrap";
import ToastService from "../common/services/toast-service";
import ApiSettingsForm from "./ApiSettingsForm";
import ApiSettingsService, { ApiSetting, ClientData } from "./ApiSettingsService";
import ApiSettingsTable from "./ApiSettingsTable";

const ApiSettings = () => {
    const [apiKeyList, setApiKeyList] = useState<ClientData[]>([]);
    const [currentApiKey, setCurrentApiKey] = useState<string>("");
    const [data, setData] = useState<ApiSetting[]>([]);

    const getData = async (): Promise<ApiSetting[] | undefined> => {
        try {
            const data = await ApiSettingsService.queryData(currentApiKey);
            setData(data);
            return data;
        } catch (error: any) {
            ToastService.showToast("Error getting queried data", error.message);
        }
    }

    useEffect(() => {
        ApiSettingsService.getFilters()
            .then((data) => setApiKeyList(data.apiKey))
            .catch(error => ToastService.showToast("Error getting filters", error));
    }, []);

    useEffect(() => {
        getData();
    }, [currentApiKey]);

    const onSettingUpdate = async (setting: ApiSetting) => {
        try {
            await ApiSettingsService.updateSetting(setting);
            await getData();
            ToastService.showToast(setting.key, "Setting updated successfully", true);
        } catch (error: any) {
            ToastService.showToast("Error updating setting", error.message);
        }
    }

    return (
        <Container>
            <Container>
                <ApiSettingsForm
                    apiKeysList={apiKeyList}
                    currentApiKey={currentApiKey}
                    onApiKeyChange={(v) => setCurrentApiKey(v)}
                />
            </Container>
            <Container>
                {data ? <ApiSettingsTable settings={data} onSettingUpdate={onSettingUpdate} /> : <Spinner color="primary" />}
            </Container>
        </Container>
    )
};

export default ApiSettings;

export interface ColumnDef {
    header: string;
    accessor: string;
}

export const columns: ColumnDef[] = [
    {
        header: 'Timestamp',
        accessor: 'createdAt'
    },
    {
        header: 'Device',
        accessor: 'deviceId'
    },
    {
        header: 'Brand',
        accessor: 'deviceBrand'
    },
    {
        header: 'Model',
        accessor: 'deviceModel'
    },
    {
        header: 'DeviceOS',
        accessor: 'deviceOS'
    },
    {
        header: 'Status',
        accessor: 'status'
    },
    {
        header: 'ErrorMessage',
        accessor: 'description'
    }
];
import axios from "../common/api/custom-axios";
import configs from "../configs";

interface VersionResponse {
    version: string;
}

const getVersion = async (): Promise<string> => {
    const res = await axios.get<VersionResponse>(`${configs.endpoint}/version`);
    return res.data.version;
};

const service = {
    getVersion,
};

export default service;

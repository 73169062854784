import { Component } from "react";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormField } from "../../common/components/form-field/FormField";
import { NewApiKeyRequest } from "../common/models/new-api-key-request";
import { APIKeyType } from '../common/models/api-key-type';

const emptyFormState = {
    name: '',
    description: '',
    expiryDate: '',
    type: APIKeyType.Mobile
} 

interface ApiKeyNewProps {
    isOpen: boolean;
    type: APIKeyType;

    toggle: () => void;
    submit: (apiKey: NewApiKeyRequest) => void
}

interface ApiKeyNewState {
    name: string;
    description: string;
    expiryDate: string;
    type: APIKeyType;
}

export class ApiKeyNewModal extends Component<ApiKeyNewProps, ApiKeyNewState> {

    constructor(props: ApiKeyNewProps) {
        super(props);
        this.state = {...emptyFormState, type: props.type};
        console.log(this.state);
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={() => this.toggle()} onClosed={() => this.resetState()}>
                <ModalHeader toggle={() => this.toggle()}>Generate Api Key</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormField
                            label="Name"
                            type="text"
                            isValid={() => this.isNameValid()}
                            onChange={val => this.setState({name: val ?? ""})} 
                            required/>
                        <FormField
                            label="Description"
                            type="text"
                            onChange={val => this.setState({description: val ?? ""})} />
                        <FormField
                            label="Expiry Date"
                            type="date"
                            isValid={() => this.isExpiryDateValid()}
                            onChange={val => this.setState({expiryDate: val ?? ""})} 
                            required/>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" disabled={!this.isFormValid()} onClick={() => this.submit()}>Generate</Button>
                    <Button color="secondary" onClick={() => this.toggle()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    }

    private isFormValid() {
        return this.isNameValid() && this.isExpiryDateValid();
    }

    private isNameValid() {
        return this.state.name.replace(/\s/g,'') !== '';
    }

    private isExpiryDateValid() {
        return this.state.expiryDate !== ''
    }

    private toggle() {
        this.props.toggle();
    }

    private submit() {
        this.props.submit(this.state);
        this.props.toggle();
    }

    private resetState() {
        this.setState({...emptyFormState});
    }
}
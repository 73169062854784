import fileDownload from "js-file-download";
import { FC, useState } from "react";
import { Button } from "reactstrap";

interface IDownloadButton {
  text: string;
  fetchFile: () => Promise<Blob>;
  importance: "primary" | "secondary";
  disabled?: Boolean;
  fileName: string;
}

const DownloadButton: FC<IDownloadButton> = (props) => {
  const [isFetching, setIsFetching] = useState(false);

  const onClick = async () => {
    try {
      setIsFetching(true);
      const file = await props.fetchFile();
      fileDownload(file, props.fileName);
    } catch {
    }

    setIsFetching(false);
  }

  return (
    <Button 
      className="search-button" 
      color={props.importance} 
      onClick={() => onClick()} 
      disabled={!!props.disabled || isFetching}
      style={{ minHeight: 38, minWidth: 120 }}
      >
        {isFetching ? "Downloading" : props.text}
    </Button>
  );
};

export default DownloadButton;
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { ReportFiltersData } from "../common/models/filters-data";
import ReportsService from "../common/services/reports-service";
import { Col, Container, Form, Row, Spinner } from "reactstrap";
import { RouteComponentProps, useHistory } from "react-router";
import { PagedResult } from "../../common/models/paged-result";
import { TransactionSummary } from "../common/models/transaction-summary-dto";
import ToastService from "../../common/services/toast-service";
import { Paginator, SelectorFormGroup, ButtonForm, DatePickerFormGroup, DownloadButton } from "../../common/components";
import TransactionList from "./components/TransactionList";

interface IFilters {
    apiKey: string;
    fromDate: string;
    toDate: string;
    sdkVersion: string;
}

const Reports: FC<RouteComponentProps> = (props) => {
    const history = useHistory();
    const [filters, setFilters] = useState<ReportFiltersData>();
    const [filtersToQuery, setFiltersToQuery] = useState<IFilters>({
        apiKey: sessionStorage.getItem("apiKey_reports") || "",
        fromDate: sessionStorage.getItem("fromDate_reports") || "",
        toDate: sessionStorage.getItem("toDate_reports") || "",
        sdkVersion: sessionStorage.getItem("sdkVersion_reports") || ""
    });
    const [data, setData] = useState<PagedResult<TransactionSummary>>();
    const [isSearching, setIsSearching] = useState<Boolean>(false);
    const { location } = props;

    const getFilters = async () => {
        const reports = await ReportsService.getFilters();
        setFilters(reports);
    }
    
    useEffect(() => {
        getFilters();
    }, []);

    useEffect(() => {
        Object.entries(filtersToQuery).forEach(x => x[1] && sessionStorage.setItem(`${x[0]}_reports`, x[1]));
    }, [filtersToQuery]);

    useEffect(() => {
        if(filtersToQuery.apiKey) {
            const params = new URLSearchParams(location?.search);
            const page = parseInt(params.get("page") || "0");
            submitSearch(page);
        } else {
            setData(undefined);
        }
    }, [filtersToQuery])

    useEffect(() => {
        if (location.search && filtersToQuery.apiKey) {
            const params = new URLSearchParams(location.search);
            const page = parseInt(params.get("page") || "0");
            submitSearch(page);
        }
    }, [location]);

    const resetFilters = () => {
        setFiltersToQuery({ apiKey: "", fromDate: "", toDate: "", sdkVersion: "" });
    };

    const submitSearch = async (page: number = 0) => {
        setData(undefined);
        setIsSearching(true);

        try {
            const res = await ReportsService.get(getServiceFilters(), page);
            setData(res);
            setIsSearching(false);
        } catch (error: any) {
            ToastService.showToast("Error applying search filters", error.response.data.detail);
            setIsSearching(false);            
        }
    };

    const downloadReports = async () => {
        try {
          const reports = await ReportsService.getCSV(getServiceFilters());
          ToastService.showToast("File downloaded.", "File downloaded to your Downloads folder.", true);
          return reports;
        } catch (error: any) {
          if (error.response.status === 403) {
            ToastService.showToast("Error downloading file.", "The requested data is too large. Try adjusting the filters.");
          } else {
            ToastService.showToast("Error downloading file.", "");
          }
    
          throw error;
        }
      };

    const changePage = (page: number) => history.push(history.location.pathname + "?page=" + page);

    const getServiceFilters = () => {
        const fromDate = filtersToQuery.fromDate ? new Date(filtersToQuery.fromDate) : null;
        const toDate = filtersToQuery.toDate ? new Date(filtersToQuery.toDate) : null;
        fromDate?.setUTCHours(0, 0, 0, 0);
        toDate?.setUTCHours(23, 59, 59, 999);

        return {
            apiKey: filtersToQuery.apiKey,
            sdkVersion: filtersToQuery.sdkVersion,
            from: fromDate,
            to: toDate,
        };
    };

    const transformApiKeyList = () => {
        const list = filters?.apiKeys.map(c => ({
            text: c.apiKeyName,
            value: c.apiKeyToken
        }));

        if (list) {
            list.unshift({ text: "", value: ""});
            return list;
        }
        
        return [];
    }

    const transformVersionsList = () => {
        const list = filters?.sdkVersions.map(v => ({ text: v, value: v }));

        if (list) {
            list.unshift({ text: "All", value: ""});
            return list;
        }
        
        return [];
    }

    const getFileName = (): string => {
        const apiKeyName = filters?.apiKeys.find(a => a.apiKeyToken === filtersToQuery.apiKey)?.apiKeyName;
        const date = (new Date()).toISOString();
        return `logs.${apiKeyName}.${date}.csv`;
      }

    const buildForm = () => (
        <Form>
            <Row form>
                <Col sm={3}>
                    <SelectorFormGroup 
                        title="Client" 
                        id="apiKeySelect" 
                        values={transformApiKeyList()} 
                        onChange={(value) => setFiltersToQuery(s => ({
                            ...filtersToQuery,
                            apiKey: value
                        }))} 
                        value={filtersToQuery.apiKey} 
                    />
                </Col>
                <Col sm={1}>
                    <SelectorFormGroup 
                        title="Version" 
                        id="versionSelect" 
                        values={transformVersionsList()}
                        onChange={(value) => setFiltersToQuery(s => ({
                            ...filtersToQuery,
                            sdkVersion: value
                        }))}
                        value={filtersToQuery.sdkVersion} 
                    />
                </Col>                
                <Col sm={2}>
                    <DatePickerFormGroup 
                        formFeedback="This field is required"
                        id="fromDate"
                        title="From"
                        onChange={(value) => setFiltersToQuery(s => ({
                            ...filtersToQuery,
                            fromDate: value
                        }))} 
                        maxValue={filtersToQuery.toDate ? new Date(filtersToQuery.toDate) : new Date()}
                        value={new Date(filtersToQuery.fromDate)}
                    />
                </Col>
                <Col sm={2}>
                    <DatePickerFormGroup 
                        formFeedback="This field is required"
                        id="toDate"
                        title="To"
                        onChange={(value) => setFiltersToQuery(s => ({
                            ...filtersToQuery,
                            toDate: value
                        }))}
                        maxValue={new Date()}
                        minValue={filtersToQuery.fromDate ? new Date(filtersToQuery.fromDate) : undefined}
                        value={new Date(filtersToQuery.toDate)}
                    />
                </Col>
                <Col className="align-self-end col-md-auto">
                    <ButtonForm text="Reset" onClick={resetFilters} importance="secondary" />
                </Col>
                <Col className="align-self-end col-md-auto">
                    <DownloadButton 
                        text="Export Data"
                        importance="primary"
                        disabled={!!!data}
                        fileName={getFileName()}
                        fetchFile={() => downloadReports()}
                    />
                </Col>
            </Row>
        </Form>
    );

    return (
        <Container>
            <Container>
                {!!!filters ? <Spinner color="primary"/> : buildForm()}
            </Container>
            {data ? 
                <Container>
                    <TransactionList result={data} searchData={{ ...filtersToQuery }} />
                    <Paginator pageCount={data.pageCount} changePage={changePage} currentPage={data.currentPage}></Paginator>
                </Container> :
                isSearching && <Spinner color="primary" />
            }
        </Container>
    );
};

export default Reports;

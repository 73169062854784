import { useEffect, useState } from "react"
import { Button, Col, Container, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap"
import { FileInput } from "../common/components/file-input/FileInput";
import ToastService from "../common/services/toast-service";
import { TermsAndConditionsFiltersData } from "./common/models/terms-conditions-filters-data";
import { UploadTermsAndConditionsRequest } from "./common/models/upload-terms-conditions-request";
import TermsAndConditionsService from "./common/services/terms-conditions-service";
import { TermsAndConditionsAddModal } from "./new/TermsAndConditionsAddModal";
import "./TermsAndConditions.css";

export const TermsAndConditions = () => {

    const [filtersData, setFiltersData] = useState<TermsAndConditionsFiltersData|undefined>(undefined);
    const [availableLocales, setAvailableLocales] = useState<string[]|undefined>(undefined);
    const [selectedLocale, setSelectedLocale] = useState<string|undefined>(undefined);
    const [file, setFile] = useState<File|undefined>(undefined);
    const [isShowingAddNewLanguageModal, setIsShowingAddNewLanguageModal] = useState<boolean>(false);

    const localeChanged = (locale: string) => {
        setSelectedLocale(locale)
    }

    const isFormValid = (): boolean => {
        return file !== undefined;
    }

    const onSave = () => {
        if (file !== undefined && selectedLocale !== undefined) {
            var request: UploadTermsAndConditionsRequest = {
                file: file,
                locale: selectedLocale
            } 
            TermsAndConditionsService.updateTermsAndConditionsFile(request)
                .then(() =>  {
                    ToastService.showToast("Success", "Terms And Conditions file updated", true)
                    TermsAndConditionsService.getFiltersData()
                        .then(data => {
                            setAvailableLocales(data.availableLocales.map(data => data.locale))
                            setFiltersData(data)
                        })
                })
                .catch(error => {
                    ToastService.showToast("Error updating terms and conditions file", error.response.data.detail ?? error.response.data.title)
                })
            resetForms()
        }
    }

    const resetForms = () => {
        setFile(undefined)
    }

    useEffect(() => {
        TermsAndConditionsService.getFiltersData()
            .then(data => {
                setAvailableLocales(data.availableLocales.map(data => data.locale))
                setFiltersData(data)
            })
    }, [])

    const toggleAddNewLanguageModal = () => {
        setIsShowingAddNewLanguageModal(!isShowingAddNewLanguageModal)
    }

    const addedNewLanguage = (language: string, file: File) => {
        TermsAndConditionsService.getFiltersData()
            .then(data => {
                setAvailableLocales(data.availableLocales.map(data => data.locale))
                setFiltersData(data)
                setSelectedLocale(language)
                setFile(file)
            })
    }

    return (
        <Container>
            <Spinner color="primary" className={filtersData ? "invisible" : "visible"}></Spinner>

            {filtersData &&
            <Form>
                <Row form>
                    <Col sm={3}>
                        <Label for="locale">Locale</Label>
                        <Input type="select" name="select" id="localeSelect" value={selectedLocale}
                            onChange={e => localeChanged(e.target.value)}>
                            <option value="" hidden></option>
                            {availableLocales?.map(locale => (
                                <option key={locale} value={locale}>{locale}</option>
                            ))}
                        </Input>
                    </Col>
                    <Col xs="auto" className="align-self-end">
                        <Button className="add_language_button" color="primary"
                            onClick={() => toggleAddNewLanguageModal()}>Add New Language</Button>
                    </Col>
                </Row>
                {selectedLocale &&
                <Container style={{marginTop: "15px"}}>
                    <Row form>
                        <FileInput label="Terms And Conditions HTML File" onFileSelected={setFile}/>
                        <Col sm={3} className="align-self-end">
                            <FormGroup>
                                <Button color="primary" disabled={!isFormValid()} onClick={() => onSave()}>Save</Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                }
            </Form>
            }
            {filtersData && selectedLocale &&
            <iframe
                title="Terms And Conditions File"
                src={filtersData?.availableLocales.find(data => data.locale === selectedLocale)?.filepath}
            ></iframe>
            }
            <TermsAndConditionsAddModal 
                isOpen={isShowingAddNewLanguageModal}
                toggle={toggleAddNewLanguageModal}
                submit={addedNewLanguage}/>
        </Container>
    )

}
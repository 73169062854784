import { FC } from "react";
import { Col, Form, Row } from "reactstrap";
import { SelectorFormGroup } from "../common/components";
import { ClientData } from "../sdk-settings/SdkSettingsService";

interface formParams {
    apiKeysList: ClientData[];
    currentApiKey: string;
    onApiKeyChange: (apiKey: string) => void;
}

const ApiSettingsForm: FC<formParams> = (form: formParams)=> {
    const transformApiKeyList = () => {
        const list = form.apiKeysList?.map(c => ({
            text: c.name,
            value: c.token
        })) || [];

        if (list) {
            list.unshift({ text: "All", value: ""});
            return list;
        }
        
        return [];
    }
    
    return (
        <Form>
            <Row form>
                <Col sm={3}>
                    <SelectorFormGroup 
                        title="Client" 
                        id="apiKeySelect" 
                        values={transformApiKeyList()} 
                        onChange={(value) => form.onApiKeyChange(value)} 
                        value={form.currentApiKey} 
                    />
                </Col>
            </Row>
        </Form>
    )
};

export default ApiSettingsForm;

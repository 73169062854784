import { PagedResult } from '../../common/models/paged-result';
import config from '../../configs';
import FileDownload from 'js-file-download'
import Moment from 'moment'
import axios from '../../common/api/custom-axios';

class LogsService {
    private logsRelUri: string = "/logs"
    private downloadRelUri: string = "/download"
    private downloadDateQueryRelUri: string = "?date="

    getPaged(searchString: string): Promise<PagedResult<Date>> {
        return axios.get(config.endpoint + this.logsRelUri + searchString).then(rsp => rsp.data)
    }

    downloadFilesForDate(date: Date): Promise<void> {

        var dateString = Moment(date).format(config.dateFormats.logFileDownloadRequest)

        return axios({
            url: config.endpoint + this.logsRelUri + this.downloadRelUri + this.downloadDateQueryRelUri + dateString,
            method: 'GET',
            responseType: 'blob', // Important
          }).then((response) => {
              FileDownload(response.data, 'Logs ' + dateString + '.zip');
          });
    }
}

const logsService = new LogsService();

export default logsService;